import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography, Button, Container, CircularProgress, Alert } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import AssistantIcon from '@mui/icons-material/Assistant';
import MicIcon from '@mui/icons-material/Mic';  // Add mic icon
import { Site } from '../types/chat';
import SiteSelectionDialog from '../components/SiteSelectionDialog';
import AudioRecorder from '../components/AudioRecorder';  // Add this import if not already present
import AudioUploader from '../components/AudioUploader';  // Add this import
import apiService from '../services/api';

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [siteDialogOpen, setSiteDialogOpen] = useState(false);
  const [recordingDialogOpen, setRecordingDialogOpen] = useState(false);  // Add recording dialog state
  const [sites, setSites] = useState<Site[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showRecorder, setShowRecorder] = useState(false);  // Add this state
  
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const isAdmin = user.role === 'admin';

  useEffect(() => {
    loadSites();
  }, []);

  const loadSites = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await apiService.sites.list();
      setSites(response.sites);
    } catch (err: any) {
      setError(err.response?.data?.detail || 'Failed to load sites');
      console.error('Error loading sites:', err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography variant="h4" sx={{ mb: 4, px: 2 }}>
        Welcome, {user.full_name}
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 4,
              height: '100%',
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h5" gutterBottom>
              General Assistant
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3, minHeight: '48px' }}>
              Get help with general construction-related questions and industry best practices.
            </Typography>
            <Button
              variant="contained"
              startIcon={<ChatIcon />}
              onClick={() => navigate('/chat/general')}
              size="large"
            >
              Start Chat
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 4,
              height: '100%',
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Site-Specific Assistants
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3, minHeight: '48px' }}>
              Access specialized assistants trained on your construction site's documentation, plans, and procedures. Get instant answers about site-specific details, safety protocols, and project requirements.
            </Typography>
            <Button
              variant="contained"
              startIcon={<AssistantIcon />}
              onClick={() => navigate('/assistant-chat')}
              color="secondary"
              size="large"
            >
              Open Site Assistants
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper 
            elevation={0} 
            sx={{ 
              p: 4,
              borderRadius: 3,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Meeting Recorder
            </Typography>
            <Typography color="text.secondary" sx={{ mb: 3 }}>
              Record and transcribe meetings with AI-powered summaries, action items, and key points. Get instant transcriptions and analysis of your meetings.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
              <AudioUploader />
            </Box>
            <AudioRecorder />
          </Paper>
        </Grid>
      </Grid>

      <SiteSelectionDialog
        open={siteDialogOpen}
        onClose={() => setSiteDialogOpen(false)}
        onSiteSelect={(siteId) => navigate(`/chat/${siteId}`)}
        assignedSites={user.assigned_sites || []}
        sites={sites}
      />
    </Container>
  );
};

export default Dashboard;

