import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Paper,
  IconButton,
  Grid,
  Alert,
  Tooltip,
  CircularProgress,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Collapse,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { 
  Delete, 
  Upload, 
  Info,
  ExpandMore,
  ExpandLess,
  Description,
  Folder,
} from '@mui/icons-material';
import apiService from '../../services/api';
import { SharePointBrowser } from '../../components/SharePointBrowser';

interface SharePointFolder {
  name: string;
  path: string;
  created: string;
  modified: string;
}

interface Assistant {
  id: string;
  name: string;
  instructions: string;
  is_active: boolean;
  sharepoint_folder?: string;
}

interface AssistantFile {
  file_id: string;
  display_name: string;  // Changed to display_name to match Azure API
  created_at: string;
  status: string;
  size: number;
}

interface SharePointFolderStats {
  totalFiles: number;
  totalSize: number;
  fileTypes: { [key: string]: number };
}

interface SharePointFile {
  name: string;
  path: string;
  size: number;
  modified: string;
}

const formatError = (error: any): string => {
  if (typeof error === 'string') return error;
  if (error?.response?.data?.detail) {
    const detail = error.response.data.detail;
    if (typeof detail === 'object') {
      return JSON.stringify(detail, null, 2);
    }
    return detail;
  }
  return error?.message || 'An error occurred';
};

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const AssistantManagement: React.FC = () => {
  const [assistants, setAssistants] = useState<Assistant[]>([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openAddFolder, setOpenAddFolder] = useState(false); // New state for add folder dialog
  const [selectedAssistant, setSelectedAssistant] = useState<Assistant | null>(null);
  const [files, setFiles] = useState<FileList | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [expandedAssistant, setExpandedAssistant] = useState<string | null>(null);
  const [assistantFiles, setAssistantFiles] = useState<Record<string, AssistantFile[]>>({});
  const [loadingFiles, setLoadingFiles] = useState<Record<string, boolean>>({});
  const [folders, setFolders] = useState<SharePointFolder[]>([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [showFolderBrowser, setShowFolderBrowser] = useState(false);
  const [folderStats, setFolderStats] = useState<SharePointFolderStats | null>(null);
  const [loadingStats, setLoadingStats] = useState(false);
  const [processingStep, setProcessingStep] = useState(0);
  const [processingFolder, setProcessingFolder] = useState(false);
  const [newAssistant, setNewAssistant] = useState({
    name: '',
    instructions: '',
    sharepoint_folder: '',
  });
  const [additionalFolder, setAdditionalFolder] = useState(''); // New state for additional folder

  const processingSteps = [
    'Creating Assistant',
    'Creating Vector Store',
    'Processing SharePoint Files',
    'Uploading Files',
    'Attaching Vector Store',
    'Saving Assistant'
  ];

  const fetchSharePointFolders = async () => {
    try {
      setLoadingFolders(true);
      const response = await apiService.assistants.listSharePointFolders();
      setFolders(response);
    } catch (error: any) {
      console.error('Error fetching SharePoint folders:', error);
      setError(formatError(error));
    } finally {
      setLoadingFolders(false);
    }
  };

  const fetchAssistants = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await apiService.assistants.list();
      setAssistants(response);
    } catch (error: any) {
      console.error('Error fetching assistants:', error);
      if (error?.response?.status !== 401) {
        setError(formatError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchAssistantFiles = async (assistantId: string) => {
    try {
      setLoadingFiles(prev => ({ ...prev, [assistantId]: true }));
      const files = await apiService.assistants.listFiles(assistantId);
      setAssistantFiles(prev => ({ ...prev, [assistantId]: files }));
    } catch (error: any) {
      console.error('Error fetching files:', error);
      if (error?.response?.status !== 401) {
        setError(formatError(error));
      }
    } finally {
      setLoadingFiles(prev => ({ ...prev, [assistantId]: false }));
    }
  };

  const fetchFolderStats = async (path: string) => {
    try {
      setLoadingStats(true);
      const files = await apiService.sharepoint.listFiles(path);
      const stats: SharePointFolderStats = {
        totalFiles: files.length,
        totalSize: files.reduce((acc: number, file: SharePointFile) => acc + file.size, 0),
        fileTypes: files.reduce((acc: { [key: string]: number }, file: SharePointFile) => {
          const ext = file.name.split('.').pop()?.toLowerCase() || 'unknown';
          acc[ext] = (acc[ext] || 0) + 1;
          return acc;
        }, {})
      };
      setFolderStats(stats);
    } catch (error) {
      console.error('Error fetching folder stats:', error);
      setFolderStats(null);
    } finally {
      setLoadingStats(false);
    }
  };

  const fetchDefaultInstructions = async () => {
    try {
      const instructions = await apiService.assistants.getDefaultInstructions();
      setNewAssistant(prev => ({ ...prev, instructions }));
    } catch (error: any) {
      console.error('Error fetching default instructions:', error);
      setError(formatError(error));
    }
  };

  useEffect(() => {
    fetchAssistants();
  }, []);

  useEffect(() => {
    if (openCreate) {
      fetchSharePointFolders();
      fetchDefaultInstructions();
    }
  }, [openCreate]);

  const handleExpandAssistant = async (assistantId: string) => {
    if (expandedAssistant === assistantId) {
      setExpandedAssistant(null);
    } else {
      setExpandedAssistant(assistantId);
      if (!assistantFiles[assistantId]) {
        await fetchAssistantFiles(assistantId);
      }
    }
  };

  const handleDeleteFile = async (assistantId: string, fileId: string) => {
    if (!window.confirm('Are you sure you want to delete this file?')) return;

    try {
      setLoading(true);
      setError(null);
      await apiService.assistants.deleteFile(assistantId, fileId);
      setSuccess('File deleted successfully');
      await fetchAssistantFiles(assistantId);
    } catch (error: any) {
      console.error('Error deleting file:', error);
      setError(formatError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleFolderSelect = async (path: string) => {
    setNewAssistant({ ...newAssistant, sharepoint_folder: path });
    setProcessingFolder(true);
    try {
      await fetchFolderStats(path);
    } finally {
      setProcessingFolder(false);
    }
    setShowFolderBrowser(false);
  };

  const handleAddFolderSelect = async (path: string) => {
    setAdditionalFolder(path);
    setShowFolderBrowser(false);
  };

  const handleCreateAssistant = async () => {
    try {
      setLoading(true);
      setError(null);
      setProcessingStep(0);

      const response = await apiService.assistants.create(
        {
          name: newAssistant.name,
          instructions: newAssistant.instructions,
          sharepoint_folder: newAssistant.sharepoint_folder,
        },
        // Add progress callback
        (step: number) => {
          console.log('Current processing step:', step);
          setProcessingStep(step);
          
          // Close dialog and show success when complete
          if (step === 5) {
            setOpenCreate(false);
            setNewAssistant({ name: '', instructions: '', sharepoint_folder: '' });
            setFolderStats(null);
            setSuccess('Site-specific assistant created successfully with SharePoint integration');
            fetchAssistants();
            setLoading(false);
          }
        }
      );

      if (!newAssistant.sharepoint_folder) {
        setOpenCreate(false);
        setNewAssistant({ name: '', instructions: '', sharepoint_folder: '' });
        setFolderStats(null);
        setSuccess('Site-specific assistant created successfully');
        await fetchAssistants();
      }
    } catch (error: any) {
      console.error('Error creating assistant:', error);
      const errorDetail = error.response?.data?.detail;
      if (errorDetail?.step !== undefined) {
        setProcessingStep(errorDetail.step);
      }
      setError(formatError(error));
    } finally {
      if (!newAssistant.sharepoint_folder) {
        setLoading(false);
        setProcessingStep(0);
      }
    }
  };

  const handleUploadFiles = async () => {
    if (!files || !selectedAssistant) return;

    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append('files', file);
    });

    try {
      setLoading(true);
      setError(null);
      await apiService.assistants.uploadDocuments(selectedAssistant.id, formData);
      setOpenUpload(false);
      setFiles(null);
      setSuccess('Site documents uploaded successfully. The assistant will now use these documents to answer questions about this construction site.');
      if (expandedAssistant === selectedAssistant.id) {
        await fetchAssistantFiles(selectedAssistant.id);
      }
    } catch (error: any) {
      console.error('Error uploading files:', error);
      setError(formatError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAssistant = async (assistant: Assistant) => {
    if (!window.confirm('Are you sure you want to delete this site-specific assistant? This will also delete all associated documents and chat history.')) return;

    try {
      setLoading(true);
      setError(null);
      await apiService.assistants.delete(assistant.id);
      setSuccess('Site-specific assistant deleted successfully');
      await fetchAssistants();
    } catch (error: any) {
      console.error('Error deleting assistant:', error);
      setError(formatError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleAddFolder = async () => {
    if (!selectedAssistant || !additionalFolder) return;

    try {
      console.log('Adding folder:', additionalFolder, 'to assistant:', selectedAssistant.id); // Debug log
      setLoading(true);
      setError(null);
      await apiService.assistants.addFolder(selectedAssistant.id, additionalFolder);
      setOpenAddFolder(false);
      setAdditionalFolder('');
      setSuccess('Additional SharePoint folder added successfully.');
      if (expandedAssistant === selectedAssistant.id) {
        await fetchAssistantFiles(selectedAssistant.id);
      }
    } catch (error: any) {
      console.error('Error adding folder:', error);
      setError(formatError(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading && assistants.length === 0) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={3}>
        <Typography variant="h6">Loading Site-Specific Assistants...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h5">Site-Specific Assistant Management</Typography>
          <Tooltip title="Create specialized AI assistants for different construction sites. Each assistant can be trained on site-specific documentation, plans, and safety protocols.">
            <Info color="action" />
          </Tooltip>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCreate(true)}
          disabled={loading}
        >
          Create New Site Assistant
        </Button>
      </Box>

      {loading && <LinearProgress sx={{ mb: 2 }} />}

      {error && (
        <Alert severity="error" sx={{ mb: 3, whiteSpace: 'pre-wrap' }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      {success && (
        <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccess(null)}>
          {success}
        </Alert>
      )}

      {assistants.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography color="textSecondary" gutterBottom>
            No site-specific assistants created yet
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Create a new assistant for each construction site to provide specialized knowledge about site documentation, safety protocols, and project requirements.
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setOpenCreate(true)}
            disabled={loading}
          >
            Create First Site Assistant
          </Button>
        </Paper>
      ) : (
        <Grid container spacing={3}>
          {assistants.map((assistant) => (
            <Grid item xs={12} key={assistant.id}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography variant="h6">{assistant.name}</Typography>
                    <IconButton
                      onClick={() => handleExpandAssistant(assistant.id)}
                      size="small"
                    >
                      {expandedAssistant === assistant.id ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Box>
                  <Box>
                    <Tooltip title="Upload Site Documents">
                      <IconButton
                        onClick={() => {
                          setSelectedAssistant(assistant);
                          setOpenUpload(true);
                        }}
                        disabled={loading}
                      >
                        <Upload />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Add SharePoint Folder">
                      <IconButton
                        onClick={() => {
                          setSelectedAssistant(assistant);
                          setOpenAddFolder(true);
                        }}
                        disabled={loading}
                      >
                        <Folder />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Site Assistant">
                      <IconButton 
                        onClick={() => handleDeleteAssistant(assistant)}
                        disabled={loading}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
                <Typography color="textSecondary" sx={{ mt: 1 }}>
                  {assistant.instructions}
                </Typography>
                {assistant.sharepoint_folder && (
                  <Box display="flex" alignItems="center" gap={1} mt={1}>
                    <Folder fontSize="small" color="action" />
                    <Typography variant="body2" color="textSecondary">
                      SharePoint Folder: {assistant.sharepoint_folder}
                    </Typography>
                  </Box>
                )}

                <Collapse in={expandedAssistant === assistant.id}>
                  <Box mt={2}>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Site Documents
                    </Typography>
                    {loadingFiles[assistant.id] ? (
                      <Box display="flex" justifyContent="center" p={2}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : assistantFiles[assistant.id]?.length ? (
                      <List dense>
                        {assistantFiles[assistant.id].map((file) => (
                          <ListItem key={file.file_id}>
                            <ListItemText
                              primary={
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Description fontSize="small" />
                                  {file.display_name}
                                </Box>
                              }
                              secondary={`${formatFileSize(file.size)} • ${new Date(file.created_at).toLocaleString()}`}
                            />
                            <ListItemSecondaryAction>
                              <Tooltip title="Delete File">
                                <IconButton
                                  edge="end"
                                  size="small"
                                  onClick={() => handleDeleteFile(assistant.id, file.file_id)}
                                  disabled={loading}
                                >
                                  <Delete fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Typography color="textSecondary" align="center">
                        No site documents uploaded yet
                      </Typography>
                    )}
                  </Box>
                </Collapse>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Create Assistant Dialog */}
      <Dialog 
        open={openCreate} 
        onClose={() => !loading && !processingFolder && setOpenCreate(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create New Site-Specific Assistant</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 2 }}>
            Create an AI assistant specialized for a specific construction site. Select a SharePoint folder containing the site's documentation, and the assistant will use these documents to provide accurate, site-specific information about plans, safety protocols, and project requirements.
          </Alert>
          <TextField
            autoFocus
            margin="dense"
            label="Site Name"
            fullWidth
            value={newAssistant.name}
            onChange={(e) => setNewAssistant({ ...newAssistant, name: e.target.value })}
            helperText="Enter the construction site name or identifier"
            required
          />
          <TextField
            margin="dense"
            label="Instructions"
            fullWidth
            multiline
            rows={4}
            value={newAssistant.instructions}
            onChange={(e) => setNewAssistant({ ...newAssistant, instructions: e.target.value })}
            helperText="Provide instructions for how the assistant should handle site-specific queries (e.g., 'Focus on safety protocols and project specifications for this site')"
            required
          />
          
          {/* SharePoint Folder Selection */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom>
              SharePoint Folder
            </Typography>
            {showFolderBrowser ? (
              <SharePointBrowser
                onFolderSelect={handleFolderSelect}
                onCancel={() => setShowFolderBrowser(false)}
              />
            ) : newAssistant.sharepoint_folder ? (
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 2, 
                  mb: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2
                }}
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Folder color="primary" />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                      {newAssistant.sharepoint_folder}
                    </Typography>
                  </Box>
                  <Button 
                    onClick={() => setShowFolderBrowser(true)}
                    size="small"
                    color="inherit"
                    disabled={processingFolder}
                  >
                    Change
                  </Button>
                </Box>

                {processingFolder ? (
                  <Box display="flex" flexDirection="column" alignItems="center" gap={2} p={2}>
                    <CircularProgress size={24} />
                    <Typography variant="body2" color="textSecondary">
                      Processing folder contents...
                    </Typography>
                  </Box>
                ) : loadingStats ? (
                  <Box display="flex" justifyContent="center" p={2}>
                    <CircularProgress size={24} />
                  </Box>
                ) : folderStats && (
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary">
                      Folder Statistics:
                    </Typography>
                    <Box sx={{ pl: 2 }}>
                      <Typography variant="body2">
                        Total Files: {folderStats.totalFiles}
                      </Typography>
                      <Typography variant="body2">
                        Total Size: {formatFileSize(folderStats.totalSize)}
                      </Typography>
                      <Typography variant="body2">
                        File Types: {Object.entries(folderStats.fileTypes).map(([ext, count]) => (
                          `${ext}: ${count}`
                        )).join(', ')}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Paper>
            ) : (
              <Button 
                onClick={() => setShowFolderBrowser(true)}
                variant="outlined"
                startIcon={<Folder />}
                sx={{ mb: 2 }}
              >
                Select Site Documents Folder
              </Button>
            )}
          </Box>

          {/* Processing Steps */}
          {loading && (
            <Box sx={{ mt: 2 }}>
              <Stepper activeStep={processingStep} orientation="vertical">
                {processingSteps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>
                      {label}
                      {processingStep === index && (
                        <CircularProgress size={16} sx={{ ml: 1 }} />
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
        </DialogContent>
        {!showFolderBrowser && (
          <DialogActions>
            <Button onClick={() => setOpenCreate(false)} disabled={loading || processingFolder}>
              Cancel
            </Button>
            <Button 
              variant="contained" 
              onClick={handleCreateAssistant}
              disabled={loading || processingFolder || !newAssistant.name || !newAssistant.instructions || !newAssistant.sharepoint_folder}
            >
              Create Site Assistant
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {/* Upload Files Dialog */}
      <Dialog 
        open={openUpload} 
        onClose={() => !loading && setOpenUpload(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Site Documents</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 2 }}>
            Upload site-specific documents such as plans, specifications, safety protocols, and project requirements. The assistant will use these documents to provide accurate information about this construction site.
          </Alert>
          <input
            type="file"
            multiple
            onChange={(e) => setFiles(e.target.files)}
            style={{ marginTop: '16px' }}
            accept=".pdf,.doc,.docx,.txt,.md,.html"
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUpload(false)} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleUploadFiles} 
            color="primary"
            disabled={loading || !files}
          >
            {loading ? <CircularProgress size={24} /> : 'Upload Site Documents'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Folder Dialog */}
      <Dialog 
        open={openAddFolder} 
        onClose={() => !loading && setOpenAddFolder(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add SharePoint Folder</DialogTitle>
        <DialogContent>
          <Alert severity="info" sx={{ mb: 2 }}>
            Add another SharePoint folder to this assistant. The assistant will be able to access and use documents from this additional folder.
          </Alert>
          
          {showFolderBrowser ? (
            <SharePointBrowser
              onFolderSelect={handleAddFolderSelect}
              onCancel={() => setShowFolderBrowser(false)}
            />
          ) : additionalFolder ? (
            <Box display="flex" alignItems="center" gap={1} mt={2}>
              <Folder fontSize="small" color="action" />
              <Typography variant="body2">
                {additionalFolder}
              </Typography>
              <Button 
                size="small"
                onClick={() => setShowFolderBrowser(true)}
              >
                Change
              </Button>
            </Box>
          ) : (
            <Button
              onClick={() => setShowFolderBrowser(true)}
              variant="outlined"
              startIcon={<Folder />}
              sx={{ mt: 2 }}
            >
              Select Additional Folder
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddFolder(false)} disabled={loading}>
            Cancel
          </Button>
          <Button 
            onClick={handleAddFolder}
            variant="contained"
            disabled={loading || !additionalFolder}
          >
            {loading ? <CircularProgress size={24} /> : 'Add Folder'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssistantManagement;
