import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import apiService from '../../services/api';

const AudioUploader: React.FC = () => {
  const [uploading, setUploading] = useState(false);
  
  console.log('AudioUploader rendering');

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      await apiService.recordings.create(formData);
      window.location.reload(); // Refresh to show new recording
    } catch (error) {
      console.error('Error uploading audio:', error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Button
      variant="outlined"
      component="label"
      startIcon={uploading ? <CircularProgress size={24} /> : <CloudUpload />}
      disabled={uploading}
      sx={{ 
        height: 36.5,
        minWidth: 140,
        visibility: 'visible',
        display: 'flex'
      }}
    >
      Upload Audio
      <input
        type="file"
        hidden
        accept="audio/*"
        onChange={handleFileUpload}
      />
    </Button>
  );
};

export default AudioUploader;