import axios from 'axios';

const api = axios.create({
  baseURL: '',  // Remove the baseURL since it's included in the endpoints
  headers: {
    'Content-Type': 'application/json',  // Set default Content-Type
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // Debug log for requests
    console.log('Request:', {
      url: config.url,
      method: config.method,
      data: config.data,
      headers: config.headers
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Debug log for errors
    console.error('Response Error:', {
      status: error.response?.status,
      data: error.response?.data,
      config: error.config
    });
    if (error.response?.status === 401) {
      // Only clear storage and redirect if not already on login page
      if (!window.location.pathname.includes('/login')) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

const apiService = {
  baseUrl: '/api/v1',  // Add baseUrl for audio file URLs
  
  auth: {
    login: async (formData: { email: string; password: string }) => {
      // Convert to URLSearchParams for OAuth2 format
      const params = new URLSearchParams();
      params.append('username', formData.email);
      params.append('password', formData.password);

      const response = await api.post('/api/v1/auth/token', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      // Store the token from OAuth2 response
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
      }

      // Return in the format expected by the auth slice
      return {
        token: response.data.access_token,
        user: response.data.user,
      };
    },
    logout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      window.location.href = '/login';
    },
  },
  admin: {
    getUsers: async () => {
      const response = await api.get('/api/v1/admin/users');
      return response.data;
    },
    getUserChats: async (userId: string) => {
      const response = await api.get(`/api/v1/admin/users/${userId}/chats`);
      return response.data;
    },
    getUserAssistantChats: async (userId: string) => {
      const response = await api.get(`/api/v1/admin/users/${userId}/assistant-chats`);
      return response.data;
    },
    updateUser: async (userId: string, data: any) => {
      const response = await api.patch(`/api/v1/admin/users/${userId}`, data);
      return response.data;
    },
    createUser: async (data: any) => {
      const response = await api.post('/api/v1/admin/users', data);
      return response.data;
    },
    changeUserPassword: async (userId: string, newPassword: string) => {
      const response = await api.patch(`/api/v1/admin/users/${userId}/password`, {
        new_password: newPassword,
      });
      return response.data;
    },
    requestUserDeletion: async (userId: string, data: {
      reason: string;
      admin_password: string;
    }) => {
      const response = await api.post(`/api/v1/admin/users/${userId}/request-deletion`, data);
      return response.data;
    },
    confirmUserDeletion: async (userId: string, data: {
      admin_password: string;
    }) => {
      const response = await api.post(`/api/v1/admin/users/${userId}/confirm-deletion`, data);
      return response.data;
    },
  },
  chat: {
    createChat: async (botType: string) => {
      const response = await api.post('/api/v1/chat/chats', { title: 'New Chat', botType });
      return response.data;
    },
    listChats: async () => {
      const response = await api.get('/api/v1/chat/chats');
      return response.data;
    },
    getChat: async (chatId: string) => {
      const response = await api.get(`/api/v1/chat/chats/${chatId}`);
      return response.data;
    },
    sendMessage: async (chatId: string, content: string) => {
      const response = await api.post(`/api/v1/chat/chats/${chatId}/messages`, { content });
      return response.data;
    },
  },
  assistants: {
    list: async () => {
      const response = await api.get('/api/v1/assistants');
      return response.data;
    },
    getStatus: async (assistantId: string) => {
      const response = await api.get(`/api/v1/assistants/${assistantId}/status`);
      return response.data;
    },
    create: async (data: { 
      name: string; 
      instructions: string;
      sharepoint_folder?: string;
    }, onProgress?: (step: number) => void) => {
      const response = await api.post('/api/v1/assistants', data);
      const assistant = response.data;

      // Start polling for status updates if SharePoint folder is provided
      if (assistant.sharepoint_folder) {
        const pollInterval = setInterval(async () => {
          try {
            const statusResponse = await api.get(`/api/v1/assistants/${assistant.id}/status`);
            const currentStep = statusResponse.data.processing_step;
            
            // Call progress callback if provided
            if (onProgress) {
              onProgress(currentStep);
            }

            // If processing is complete or failed, stop polling
            if (currentStep === 5 || statusResponse.data.error) {
              clearInterval(pollInterval);
            }
          } catch (error) {
            console.error('Error polling assistant status:', error);
            clearInterval(pollInterval);
          }
        }, 1000); // Poll every second
      }

      return response.data;
    },
    uploadDocuments: async (assistantId: string, files: FormData) => {
      const response = await api.post(`/api/v1/assistants/${assistantId}/documents`, files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    listFiles: async (assistantId: string) => {
      const response = await api.get(`/api/v1/assistants/${assistantId}/files`);
      return response.data;
    },
    deleteFile: async (assistantId: string, fileId: string) => {
      const response = await api.delete(`/api/v1/assistants/${assistantId}/files/${fileId}`);
      return response.data;
    },
    delete: async (assistantId: string) => {
      const response = await api.delete(`/api/v1/assistants/${assistantId}`);
      return response.data;
    },
    listAssigned: async () => {
      const response = await api.get('/api/v1/assistants/assigned');
      return response.data;
    },
    listChats: async () => {
      const response = await api.get('/api/v1/assistants/chats');
      return response.data;
    },
    createChat: async (assistantId: string) => {
      const response = await api.post(`/api/v1/assistants/${assistantId}/chats`);
      return response.data;
    },
    getChatMessages: async (chatId: string) => {
      const response = await api.get(`/api/v1/assistants/chats/${chatId}/messages`);
      return response.data;
    },
    sendMessage: async (chatId: string, content: string) => {
      const response = await api.post(`/api/v1/assistants/chats/${chatId}/messages`, { content });
      return response.data;
    },
    // New SharePoint endpoints
    listSharePointFolders: async () => {
      const response = await api.get('/api/v1/assistants/sharepoint/folders');
      return response.data.folders;
    },
    listSharePointFiles: async (folderPath: string) => {
      const response = await api.get(`/api/v1/assistants/sharepoint/files/${encodeURIComponent(folderPath)}`);
      return response.data.files;
    },
    // New endpoint for getting default instructions
    getDefaultInstructions: async () => {
      const response = await api.get('/api/v1/assistants/default-instructions');
      return response.data.instructions;
    },
    // New endpoint for adding a folder to the vector store
    addFolder: async (assistantId: string, folderPath: string) => {
      console.log('API call to add folder:', folderPath, 'to assistant:', assistantId);
      const response = await api.post(`/api/v1/assistants/${assistantId}/add-folder?folder_path=${encodeURIComponent(folderPath)}`);
      return response.data;
    },
  },
  users: {
    list: async () => {
      const response = await api.get('/api/v1/admin/users');
      return response.data;
    },
    update: async (userId: string, data: any) => {
      const response = await api.patch(`/api/v1/admin/users/${userId}`, data);
      return response.data;
    },
    create: async (data: any) => {
      const response = await api.post('/api/v1/admin/users', data);
      return response.data;
    },
  },
  sites: {
    list: async () => {
      const response = await api.get('/api/v1/admin/sites');
      return response.data;
    },
    create: async (data: any) => {
      const response = await api.post('/api/v1/admin/sites', data);
      return response.data;
    },
    update: async (siteId: string, data: any) => {
      const response = await api.patch(`/api/v1/admin/sites/${siteId}`, data);
      return response.data;
    },
    delete: async (siteId: string) => {
      const response = await api.delete(`/api/v1/admin/sites/${siteId}`);
      return response.data;
    },
  },
  analytics: {
    getOverview: async () => {
      const response = await api.get('/api/v1/admin/analytics/overview');
      return response.data;
    },
  },
  siteBots: {
    list: async () => {
      const response = await api.get('/api/v1/admin/site-bots');
      return response.data;
    },
    create: async (data: any) => {
      const response = await api.post('/api/v1/admin/site-bots', data);
      return response.data;
    },
    update: async (botId: string, data: any) => {
      const response = await api.patch(`/api/v1/admin/site-bots/${botId}`, data);
      return response.data;
    },
    delete: async (botId: string) => {
      const response = await api.delete(`/api/v1/admin/site-bots/${botId}`);
      return response.data;
    },
    uploadDocuments: async (botId: string, files: FormData) => {
      const response = await api.post(`/api/v1/admin/site-bots/${botId}/documents`, files, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
  },
  sharepoint: {
    listFiles: async (path: string) => {
      const response = await api.get(`/api/v1/sharepoint/files/${encodeURIComponent(path)}`);
      return response.data;
    },
    listFolders: async (path: string) => {
      const response = await api.get(`/api/v1/sharepoint/folders/${encodeURIComponent(path)}`);
      return response.data;
    },
    getLibraryRoot: async () => {
      const response = await api.get('/api/v1/sharepoint/library-root');
      return response.data;
    },
  },
  // Add recordings service
  recordings: {
    create: async (formData: FormData) => {
      const response = await api.post('/api/v1/recordings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    },
    list: async () => {
      const response = await api.get('/api/v1/recordings');
      return response.data;
    },
    get: async (recordingId: string) => {
      const response = await api.get(`/api/v1/recordings/${recordingId}`);
      return response.data;
    },
    delete: async (recordingId: string) => {
      const response = await api.delete(`/api/v1/recordings/${recordingId}`);
      return response.data;
    },
    getEmailContent: async (recordingId: string) => {
      const response = await api.get(`/api/v1/recordings/${recordingId}/email`);
      return response.data;
    },
  },
};

export default apiService;
