import React from 'react';
import { Box, useTheme } from '@mui/material';
import { keyframes } from '@mui/system';

interface Props {
  isRecording: boolean;
}

const WaveForm: React.FC<Props> = ({ isRecording }) => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.main;

  // Define wave animation
  const wave = keyframes`
    0%, 100% {
      transform: scaleY(0.2);
    }
    50% {
      transform: scaleY(1);
    }
  `;

  // Create array of bars with different animation delays and heights
  const bars = Array(30).fill(0).map((_, i) => ({
    delay: `${(i * 0.05).toFixed(2)}s`,
    height: Math.random() * 0.8 + 0.2, // Random height between 0.2 and 1
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
        height: 60,
        mx: 2,
        opacity: isRecording ? 1 : 0,
        transition: theme.transitions.create('opacity', {
          duration: theme.transitions.duration.shorter,
        }),
      }}
    >
      {bars.map((bar, i) => (
        <Box
          key={i}
          sx={{
            width: 3,
            height: '100%',
            backgroundColor: color,
            animation: isRecording ? `${wave} ${0.8 + Math.random() * 0.4}s ease-in-out infinite` : 'none',
            animationDelay: bar.delay,
            transform: `scaleY(${bar.height})`,
            borderRadius: 1,
            transition: theme.transitions.create(['transform', 'opacity'], {
              duration: theme.transitions.duration.shortest,
            }),
            '&:hover': {
              opacity: 0.8,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default WaveForm;
