import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Dialog,
  Tooltip,
  Alert,
} from '@mui/material';
import {
  Mic,
  Stop,
  PlayArrow,
  Pause,
  Email,
  Delete,
} from '@mui/icons-material';
import { formatDistanceToNow, addHours } from 'date-fns';
import apiService from '../../services/api';
import RecordingDetails from './RecordingDetails';
import WaveForm from './WaveForm';

interface Recording {
  id: string;
  filename: string;
  transcript: string;
  analysis: {
    summary: string;
    key_points: string[];
    action_items: string[];
    dates: string[];
  };
  created_at: string;
}

const AudioRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [currentlyPlaying, setCurrentlyPlaying] = useState<string | null>(null);
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPaused, setIsPaused] = useState(false);
  
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const audioChunks = useRef<Blob[]>([]);
  const audioPlayer = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    fetchRecordings();
  }, []);

  const fetchRecordings = async () => {
    try {
      const response = await apiService.recordings.list();
      setRecordings(response);
    } catch (error: any) {
      console.error('Error fetching recordings:', error);
      setError('Failed to load recordings');
    }
  };

  const getSupportedMimeType = () => {
    const types = [
      'audio/webm;codecs=opus',
      'audio/mp4;codecs=mp4a',
      'audio/aac',
      'audio/mpeg',
      'audio/wav'
    ];

    for (const type of types) {
      if (MediaRecorder.isTypeSupported(type)) {
        return type;
      }
    }
    throw new Error('No supported audio format found. Please try a different browser.');
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Get supported mime type
      const mimeType = getSupportedMimeType();
      console.log('Using audio format:', mimeType);
      
      mediaRecorder.current = new MediaRecorder(stream, {
        mimeType,
        audioBitsPerSecond: 128000  // 128 kbps for good quality
      });
      
      audioChunks.current = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorder.current.onpause = () => {
        setIsPaused(true);
      };

      mediaRecorder.current.onresume = () => {
        setIsPaused(false);
      };

      mediaRecorder.current.onstop = async () => {
        const audioBlob = new Blob(audioChunks.current, { type: mimeType });
        await handleUpload(audioBlob);
      };

      mediaRecorder.current.start(100);  // Collect data every 100ms
      setIsRecording(true);
      setIsPaused(false);
      setError(null);
    } catch (error: any) {
      console.error('Error starting recording:', error);
      setError(error.message || 'Could not access microphone. Please ensure you have granted permission.');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state !== 'inactive') {
      mediaRecorder.current.stop();
      setIsRecording(false);
      
      // Stop all tracks
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
    }
  };

  const handleUpload = async (audioBlob: Blob) => {
    try {
      setIsProcessing(true);
      const formData = new FormData();
      
      // Get the file extension based on mime type
      const extension = audioBlob.type.includes('webm') ? 'webm' : 
                       audioBlob.type.includes('mp4') ? 'm4a' :
                       audioBlob.type.includes('aac') ? 'aac' :
                       audioBlob.type.includes('mpeg') ? 'mp3' : 'wav';
      
      formData.append('file', audioBlob, `recording.${extension}`);

      const response = await apiService.recordings.create(formData);
      await fetchRecordings();
      setError(null);
    } catch (error: any) {
      console.error('Error uploading recording:', error);
      setError(error.response?.data?.detail || 'Failed to process recording. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePlay = async (recording: Recording) => {
    try {
      if (!audioPlayer.current) {
        audioPlayer.current = new Audio();
      }

      if (currentlyPlaying === recording.id) {
        audioPlayer.current.pause();
        setCurrentlyPlaying(null);
      } else {
        // Get token from localStorage
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication required');
        }

        // Fetch audio with authentication
        const audioResponse = await fetch(
          `${apiService.baseUrl}/recordings/audio/${recording.filename}`,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
        
        if (!audioResponse.ok) {
          throw new Error(
            audioResponse.status === 401 
              ? 'Authentication required' 
              : 'Failed to load audio file'
          );
        }
        
        // Create blob URL from the response
        const blob = await audioResponse.blob();
        const blobUrl = URL.createObjectURL(blob);

        // Set up audio player
        audioPlayer.current.src = blobUrl;
        audioPlayer.current.onplay = () => setCurrentlyPlaying(recording.id);
        audioPlayer.current.onpause = () => setCurrentlyPlaying(null);
        audioPlayer.current.onended = () => {
          setCurrentlyPlaying(null);
          URL.revokeObjectURL(blobUrl);  // Clean up blob URL
        };
        
        // Start playback
        await audioPlayer.current.play();
      }
    } catch (error: any) {
      console.error('Error playing recording:', error);
      setError(error.message || 'Failed to play recording');
      setCurrentlyPlaying(null);
    }
  };

  const handleDelete = async (recording: Recording) => {
    if (!window.confirm('Are you sure you want to delete this recording?')) return;
    
    try {
      await apiService.recordings.delete(recording.id);
      await fetchRecordings();
      setError(null);
    } catch (error) {
      console.error('Error deleting recording:', error);
      setError('Failed to delete recording');
    }
  };

  const handleEmail = async (recording: Recording) => {
    try {
      const response = await apiService.recordings.getEmailContent(recording.id);
      window.location.href = response.mailto;
    } catch (error) {
      console.error('Error generating email:', error);
      setError('Failed to generate email content');
    }
  };

  const formatDate = (dateString: string) => {
    // Add 2 hours to convert to UTC+2
    const date = addHours(new Date(dateString), 2);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
      setIsPaused(true);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
      setIsPaused(false);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 3 }}>
        <Box display="flex" alignItems="center" gap={2}>
          {/* Recording Controls */}
          <Button
            variant="contained"
            color={isRecording ? "error" : "primary"}
            startIcon={isRecording ? (isPaused ? <PlayArrow /> : <Pause />) : <Mic />}
            onClick={
              !isRecording 
                ? startRecording 
                : isPaused 
                  ? resumeRecording 
                  : pauseRecording
            }
            disabled={isProcessing}
          >
            {!isRecording 
              ? "Start Recording" 
              : isPaused 
                ? "Resume Recording" 
                : "Pause Recording"
            }
          </Button>

          {isRecording && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<Stop />}
              onClick={stopRecording}
              disabled={isProcessing}
            >
              Stop Recording
            </Button>
          )}

          {/* Processing Indicator */}
          {isProcessing && (
            <Box display="flex" alignItems="center" gap={1}>
              <CircularProgress size={24} />
              <Typography>Processing recording...</Typography>
            </Box>
          )}
        </Box>

        {/* Wave Form */}
        <WaveForm isRecording={isRecording} />
      </Box>

      {/* Recordings List */}
      <List>
        {recordings.map((recording) => (
          <ListItem
            key={recording.id}
            sx={{
              mb: 1,
              bgcolor: 'background.paper',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider',
            }}
          >
            <ListItemText
              primary={formatDate(recording.created_at)}
              secondary={recording.analysis.summary}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedRecording(recording)}
            />
            <ListItemSecondaryAction>
              <Tooltip title={currentlyPlaying === recording.id ? "Pause" : "Play"}>
                <IconButton onClick={() => handlePlay(recording)}>
                  {currentlyPlaying === recording.id ? <Pause /> : <PlayArrow />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Email Summary">
                <IconButton onClick={() => handleEmail(recording)}>
                  <Email />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Recording">
                <IconButton onClick={() => handleDelete(recording)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      {/* Recording Details Dialog */}
      <Dialog
        open={!!selectedRecording}
        onClose={() => setSelectedRecording(null)}
        maxWidth="md"
        fullWidth
      >
        {selectedRecording && (
          <RecordingDetails
            recording={{
              ...selectedRecording,
              created_at: formatDate(selectedRecording.created_at)
            }}
            onClose={() => setSelectedRecording(null)}
            onEmail={() => handleEmail(selectedRecording)}
          />
        )}
      </Dialog>
    </Box>
  );
};

export default AudioRecorder;
