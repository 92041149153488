import React from 'react';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  useTheme,
} from '@mui/material';
import {
  Assignment,
  CalendarToday,
  CheckCircle,
  Subject,
} from '@mui/icons-material';

interface Recording {
  id: string;
  filename: string;
  transcript: string;
  analysis: {
    summary: string;
    key_points: string[];
    action_items: string[];
    dates: string[];
  };
  created_at: string;  // This is now a pre-formatted string
}

interface Props {
  recording: Recording;
  onClose: () => void;
  onEmail: () => void;
}

const RecordingDetails: React.FC<Props> = ({ recording, onClose, onEmail }) => {
  const theme = useTheme();

  return (
    <>
      <DialogTitle>
        Meeting Recording
        <Typography variant="subtitle2" color="textSecondary">
          {recording.created_at}  {/* Use the pre-formatted date directly */}
        </Typography>
      </DialogTitle>
      
      <DialogContent>
        <Box mb={3}>
          <Typography variant="h6" gutterBottom display="flex" alignItems="center" gap={1}>
            <Subject fontSize="small" /> Summary
          </Typography>
          <Typography>{recording.analysis.summary}</Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box mb={3}>
          <Typography variant="h6" gutterBottom display="flex" alignItems="center" gap={1}>
            <Assignment fontSize="small" /> Key Points
          </Typography>
          <List dense>
            {recording.analysis.key_points.map((point, index) => (
              <ListItem key={index}>
                <ListItemText primary={point} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box mb={3}>
          <Typography variant="h6" gutterBottom display="flex" alignItems="center" gap={1}>
            <CheckCircle fontSize="small" /> Action Items
          </Typography>
          <List dense>
            {recording.analysis.action_items.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box mb={3}>
          <Typography variant="h6" gutterBottom display="flex" alignItems="center" gap={1}>
            <CalendarToday fontSize="small" /> Important Dates
          </Typography>
          <List dense>
            {recording.analysis.dates.map((date, index) => (
              <ListItem key={index}>
                <ListItemText primary={date} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography variant="h6" gutterBottom>Transcript</Typography>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'grey.100',
              color: theme.palette.text.primary,
              p: 2,
              borderRadius: 1,
              border: '1px solid',
              borderColor: theme.palette.divider,
            }}
          >
            {recording.transcript}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={onEmail} variant="contained" color="primary">
          Email Summary
        </Button>
      </DialogActions>
    </>
  );
};

export default RecordingDetails;
